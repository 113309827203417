<template>
  <div class="container mx-auto mt-8">
    <h2 class="text-xl font-bold mb-4">Movimientos</h2>
    <div class="bg-white rounded-lg shadow-md">
      <div class="p-4">
        <OrderItem
          v-for="order in orders"
          :key="order.id"
          :avatarSrc="order.avatarSrc"
          :customerName="order.customerName"
          :amount="order.amount"
          :date="order.date"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrderItem from "./OrderItem.vue";

export default {
  name: "OrdersList",
  components: {
    OrderItem,
  },
  data() {
    return {
      orders: [
        {
          id: 1,
          avatarSrc: "ruta/a/avatar1.jpg",
          customerName: "Autor A",
          amount: 50,
          date: "2023-07-27",
        },
        {
          id: 2,
          avatarSrc: "ruta/a/avatar2.jpg",
          customerName: "Autor B",
          amount: 75,
          date: "2023-07-26",
        },
        // Agregar más movimientos aquí
      ],
    };
  },
};
</script>
