<template>
  <div class="flex items-center mb-4">
    <img :src="avatarSrc" alt="Avatar" class="w-10 h-10 rounded-full mr-4" />
    <div>
      <p class="font-bold">{{ customerName }}</p>
      <p class="text-gray-600">Monto pagado: ${{ amount }}</p>
      <p class="text-gray-600">Fecha: {{ date }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderItem",
  props: {
    avatarSrc: String,
    customerName: String,
    amount: Number,
    date: String,
  },
};
</script>
