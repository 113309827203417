<template>
  <nav class="bg-main p-4 fixed top-0 w-full">
    <div class="container mx-auto flex justify-between items-center text-white">
      <div class="text-xl font-bold">Billetera Orsai Beta</div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style>
/* Add styles for the main color palette */
.bg-main {
  background-color: #9b3565;
}
</style>
