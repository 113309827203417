<template>
  <div class="bg-gray-100">
    <NavBar />
    <BalanceCard :totalAmount="1255" />
    <OrdersList />
    <BottomNavBar />
  </div>
</template>

<script>
import NavBar from "@/components/billetera/NavBar.vue";
import BalanceCard from "@/components/billetera/BalanceCard.vue";
import OrdersList from "@/components/billetera/OrdersList.vue";
import BottomNavBar from "@/components/billetera/BottomNavBar.vue";

export default {
  layout: "mobile",
  components: {
    NavBar,
    BalanceCard,
    OrdersList,
    BottomNavBar,
  },
};
</script>

<style>
/* Add any additional styles you may need here */
</style>
